import clsx from 'clsx';
import { useMemo, type FC } from 'react';
import styled from 'styled-components';

import { media } from 'src/styles';
import { MenuList } from './menu-list';

type Props = {
  className?: string;
};

const DEFAULT_MENUS = [
  {
    name: 'work',
    label: 'works',
  },
  {
    name: 'about',
    label: 'about',
    href: '/about',
  },
  {
    name: 'shop',
    label: 'shop',
    href: 'https://villager.theshop.jp/',
  },
  {
    name: 'publishing',
    label: '村畑出版',
    href: 'https://villager.theshop.jp/categories/5980531',
    isJapanese: true,
  },
];

export const Navigation: FC<Props> = ({ className }) => {
  const menus = useMemo(() => DEFAULT_MENUS, []);
  return (
    <Wrapper role="navigation" className={clsx(className)}>
      <MenuList menus={menus} />
    </Wrapper>
  );
};

const Wrapper = styled.nav`
  font-size: ${({ theme }) => theme.font.size.headerSp};
  font-size: clamp(
    ${({ theme }) => theme.font.size.normal},
    ${({ theme }) => theme.font.size.headerSp},
    ${({ theme }) => theme.font.size.large}
  );
  line-height: 1.7;
  * {
    line-height: inherit;
  }
  ${media.ipadVerticalOrMore`
    font-size: ${({ theme }) => theme.font.size.large};
  `}
`;

export default Navigation;
